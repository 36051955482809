import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import GetStartedSection from '../GetStartedSection';
import Footer from '../MainFooter';
import MainHeader from '../MainHeader';
import RequestDemoModal from '../RequestDemoModal';
import DataCardsSection from './components/DataCardsSection';
import OurPlatformSection from './components/OurPlatformSection';
import SuperchargedCommerceSection from './components/SuperchargedCommerceSection';
import UnifiedPlatformSection from './components/UnifiedPlatformSection';
import WePowerSection from './components/WePowerSection';
import '../../styles/main.scss';

const Platform = inject('store')(
    observer(({ store, history }) => {
        const superchargedSection = useRef(null);
        const unifiedPlatformSection = useRef(null);

        const onScrollTo = (ref) => {
            if (ref) ref.current.scrollIntoView({ behavior: 'smooth' });
        };

        useEffect(() => {
            const scrollTo = new URLSearchParams(history.location.search).get('scrollTo');
            const query = new URLSearchParams(history.location.search);

            if (scrollTo === 'supercharged') {
                onScrollTo(superchargedSection);
                query.delete('scrollTo');
                history.replace({ search: query.toString() });
            }

            if (scrollTo === 'unifiedPlatform') {
                onScrollTo(unifiedPlatformSection);
                query.delete('scrollTo');
                history.replace({ search: query.toString() });
            }
        }, [unifiedPlatformSection]);

        return (
            <div className='main-page-wrapper'>
                <MainHeader showLinks showAuth />
                <OurPlatformSection />
                <SuperchargedCommerceSection targetRef={superchargedSection} />
                <WePowerSection />
                <UnifiedPlatformSection targetRef={unifiedPlatformSection} />
                <DataCardsSection />
                <GetStartedSection bgColor='light-green' requestDemoModal={() => store.modalStore.toggleModal(true)} />
                <Footer />

                <div className={`modal-overlay ${store.modalStore.modalDisplay ? 'modal-overlay--active' : ''}`}>
                    <RequestDemoModal />
                </div>
            </div>
        );
    })
);

export default Platform;
