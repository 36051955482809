import React from 'react';
import GradientBackground from '../../../../shared/components/GradientBackground';
import './index.scss';

const OurCustomersSection = () => (
    <div className='klickly-our-customers-wrapper'>
        <GradientBackground />
        <div className='klickly-our-customers'>
            <div className='klickly-our-customers__content'>
                <div className='klickly-our-customers__title'>Our Customers</div>
                <div className='klickly-our-customers__text'>
                    Some of the world’s most forward-thinking companies use <br /> Klickly to achieve breakthrough
                    results.
                </div>
            </div>
        </div>
    </div>
);
export default OurCustomersSection;
