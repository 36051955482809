import * as React from 'react';
import { inject, observer } from 'mobx-react';
import GetStartedSection from '../GetStartedSection';
import Footer from '../MainFooter';
import MainHeader from '../MainHeader';
import RequestDemoModal from '../RequestDemoModal';
import Testimonials from '../Testimonials';
import BrandsCardsSection from './components/BrandsCardsSection';
import OurCustomersSection from './components/OurCustomersSection';
import '../../styles/main.scss';

const Customers = inject('store')(
    observer(({ store }) => {
        return (
            <div className='main-page-wrapper'>
                <MainHeader showLinks showAuth />
                <OurCustomersSection />
                <BrandsCardsSection />
                <Testimonials />
                <GetStartedSection
                    gradientType='pink-invert'
                    requestDemoModal={() => store.modalStore.toggleModal(true)}
                />
                <Footer />

                <div className={`modal-overlay ${store.modalStore.modalDisplay ? 'modal-overlay--active' : ''}`}>
                    <RequestDemoModal />
                </div>
            </div>
        );
    })
);
export default Customers;
