import React from 'react';
import classnames from 'classnames';
import reward1 from '../../../../images/main/reward-1.png';
import reward2 from '../../../../images/main/reward-2.png';
import reward3 from '../../../../images/main/reward-3.png';
import GradientBackground from '../../../../shared/components/GradientBackground';
import { cards } from './cards';
import './index.scss';
const BrandsCardsSection = () => {
    return (
        <div className='klickly-brands-cards-wrapper'>
            <GradientBackground gradientType='orange' direction='left' />
            <div className='klickly-brands-cards'>
                <div className='klickly-brands-cards-content'>
                    {cards.map((card) => {
                        return (
                            <div className='klickly-brands-cards__card' key={card.id}>
                                <img
                                    src={card.image}
                                    className={classnames(
                                        'klickly-brands-cards__card-image',
                                        `klickly-brands-cards__card-image_${card.id}`
                                    )}
                                    alt={card.name}
                                />
                                {card.text}
                            </div>
                        );
                    })}
                </div>
                <div className='klickly-brands-cards__rewards'>
                    <img
                        src={reward1}
                        className='klickly-brands-cards__reward klickly-brands-cards__reward-1'
                        alt='Reward'
                    />
                    <img
                        src={reward2}
                        className='klickly-brands-cards__reward klickly-brands-cards__reward-2'
                        alt='Reward'
                    />
                    <img
                        src={reward3}
                        className='klickly-brands-cards__reward klickly-brands-cards__reward-3'
                        alt='Reward'
                    />
                </div>
            </div>
        </div>
    );
};

export default BrandsCardsSection;
