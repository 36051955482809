import React from 'react';
import classNames from 'classnames';
import './index.scss';

const Textarea = ({ type, value, placeholder, label, name, id, additionalClassname, errorMessage, onChange }) => {
    const handleInputChange = (data) => {
        onChange(data.target.value, name);
    };
    return (
        <div
            className={classNames('klickly-textarea-wrapper', additionalClassname, {
                'textarea-with-error': Boolean(errorMessage)
            })}
        >
            <textarea
                className='klickly-textarea'
                type={type}
                id={id || name}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={handleInputChange}
            />

            {label ? (
                <label htmlFor={id || name} className='klickly-textarea__label'>
                    <span
                        className={classNames('klickly-textarea__label-text', {
                            'klickly-textarea__label-text_on-top': Boolean(value)
                        })}
                    >
                        {label}
                    </span>
                </label>
            ) : null}

            <div className='error-message'>{errorMessage || ' '}</div>
        </div>
    );
};

export default Textarea;
