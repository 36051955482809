import { flow, types } from 'mobx-state-tree';
import * as Validator from 'validatorjs';
import FormSerializer from '../utils/FormSerializer';

export const ModalSore = types
    .model('ModalSore', {
        modalDisplay: false,
        showSuccess: false,
        refForm: types.maybeNull(types.frozen()),
        showSpinner: false,
        errors: types.model({
            name: types.string,
            email: types.string,
            phone: types.string,
            typeOfCompany: types.string,
            subject: types.string,
            message: types.string
        }),
        isValid: false
    })
    .views((self) => ({}))
    .actions((self) => {
        const onChange = (value, name) => {
            self.errors[name] = '';
        };
        const clearForm = (refForm) => {
            if (refForm) refForm.reset();
            self.errors = { name: '', email: '', phone: '', typeOfCompany: '', subject: '', message: '' };
        };
        const toggleModal = (status) => {
            self.modalDisplay = status;
            clearForm();
        };

        const validate = (data, rules, fieldLabels = null) => {
            const validation = new Validator(data, rules);

            if (fieldLabels) {
                validation.setAttributeNames(fieldLabels);
            }

            validation.fails();
            validation.passes();
            self.errors = {
                name: validation.errors.errors?.name?.[0] || '',
                email: validation.errors.errors?.email?.[0] || '',
                phone: validation.errors.errors?.phone?.[0] || '',
                typeOfCompany: validation.errors.errors?.typeOfCompany?.[0] || '',
                subject: validation.errors.errors?.subject?.[0] || '',
                message: validation.errors.errors?.message?.[0] || ''
            };
            self.isValid = Object.values(self.errors).every((err) => !err);
        };

        const validateForm = (data) => {
            const rules = {
                name: 'required|min:3',
                email: [
                    'required',
                    'regex:/^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$/'
                ],
                phone: 'required',
                typeOfCompany: 'required'
            };

            const labels = {
                name: 'name',
                email: 'email',
                phone: 'phone',
                typeOfCompany: 'type of company'
            };

            validate(data, rules, labels);
        };

        const submit = flow(function* submit(refForm) {
            const data = FormSerializer.serialize(refForm);
            validateForm(data);

            if (self.isValid) {
                self.showSpinner = true;
                const url = process.env.REACT_APP_SEND_REQUEST_EMAIL_URL;
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data),
                    credentials: 'include'
                };
                try {
                    yield fetch(url, requestOptions);
                    self.showSpinner = false;
                    self.showSuccess = true;
                    clearForm(refForm);
                    setTimeout(() => {
                        self.modalDisplay = false;
                        self.showSuccess = false;
                    }, 3000);
                } catch (e) {
                    return Promise.reject(e);
                }
            }
        });

        const setFieldError = (field, error) => {
            self.errors[field] = error;
        };

        return { onChange, toggleModal, submit, setFieldError };
    });
