import React from 'react';
import './index.scss';

/* eslint-disable */
const Partner = ({ partner, elemRef }) => {
    const { imgName = '', category = '', name = '', about = '', offer = '', link = '' } = partner;
    return (
        <div className='q4-partner-wrapper' ref={elemRef}>
            <div className='q4-partner-content'>
                <div className='q4-partner__logo-wrapper'>
                    <img className='q4-partner__logo-img' src={imgName} alt='partner logo'/>
                </div>
                <div className='q4-partner__info'>
                    <div>
                        <div className='q4-partner__name'>{name}</div>
                        <div className='q4-partner__category'>Category: {category}</div>
                    </div>
                    <div className='q4-partner__about'>{about}</div>
                    <div className='q4-partner__offer'>[{offer}]</div>
                </div>
            </div>
            <div className='q4-partner__button-wrapper'>
                <div className='q4-partner__button' onClick={() => window.open(link)}>
                    Get Deal
                </div>
            </div>
        </div>
    );
};
export default Partner;
