import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

const Footer = () => {
    const onLogoClick = () => {
        window.open('/', '_self');
    };

    return (
        <footer className='klickly-footer'>
            <div className='klickly-footer__content'>
                <div className='footer-side'>
                    <div className='footer-side-item'>
                        {/* eslint-disable-next-line */}
                        <div onClick={onLogoClick} className='footer-logo' />
                    </div>
                    <div className='footer-side-item'>
                        <div className='klickly-footer__copyright'>© Klickly, Inc</div>
                    </div>
                </div>
                <div className='footer-side'>
                    <div className='footer-side-item'>
                        <Link
                            to='/docs/terms'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='klickly-footer__nav-link'
                        >
                            Terms
                        </Link>
                    </div>
                    <div className='footer-side-item'>
                        <Link
                            to='/docs/privacy'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='klickly-footer__nav-link'
                        >
                            Privacy
                        </Link>
                    </div>
                    <div className='footer-side-item footer-side-item-mobile'>
                        <Link
                            to='/press'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='klickly-footer__nav-link'
                        >
                            Press
                        </Link>
                    </div>
                    <div className='footer-side-item'>
                        <Link
                            to='/docs/privacy/opt_out'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='klickly-footer__nav-link'
                        >
                            Do Not Sell/Share My Personal Information
                        </Link>
                    </div>
                    <div className='footer-side-item'>
                        <Link
                            to='/docs/disclosure-sensitive-info'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='klickly-footer__nav-link klickly-sensitive-info'
                        >
                            Limit the Use of My Sensitive Personal Information
                        </Link>
                    </div>
                    <div className='footer-side-item footer-side-item-desktop'>
                        <Link
                            to='/press'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='klickly-footer__nav-link'
                        >
                            Press
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default Footer;
