import React from 'react';
import nutrition from '../../images/main/companies/310Nutrition.svg';
import anese from '../../images/main/companies/anese.svg';
import bloom from '../../images/main/companies/bloom.svg';
import riot from '../../images/main/companies/riot.svg';
import utz from '../../images/main/companies/utz.svg';
import vegamour from '../../images/main/companies/vegamour.svg';
import './index.scss';

const Testimonials = ({ targetRef }) => {
    return (
        <div className='klickly-testimonials' ref={targetRef}>
            <div className='klickly-testimonials__content'>
                <div className='klickly-testimonials__head'>Testimonials</div>
                <div className='klickly-testimonials__title'>Thousands of brands love Klickly</div>
                <div className='klickly-testimonials__items'>
                    <div className='klickly-testimonials__item'>
                        <img
                            src={utz}
                            className='klickly-testimonials__item-image klickly-testimonials__item-image_utz'
                            alt='company logo'
                        />
                        <div className='klickly-testimonials__item-text'>
                            “Klickly has been one of the most effective tools that we’ve used. The ability that Klickly
                            has to get me a high return has actually blown everything else out of the water that we’ve
                            tried.”
                        </div>
                    </div>
                    <div className='klickly-testimonials__item'>
                        <img
                            src={nutrition}
                            className='klickly-testimonials__item-image klickly-testimonials__item-image_310'
                            alt='company logo'
                        />
                        <div className='klickly-testimonials__item-text'>
                            “I’ve been excited with the performance we’re seeing from Klickly. Setup was seamless … It’s
                            nice to have complete control of my ROAS.”
                        </div>
                    </div>
                    <div className='klickly-testimonials__item'>
                        <img
                            src={riot}
                            className='klickly-testimonials__item-image klickly-testimonials__item-image_riot'
                            alt='company logo'
                        />
                        <div className='klickly-testimonials__item-text'>
                            “Your platform is a game changer and should be pushed throughout the eCommerce space.”
                        </div>
                    </div>
                    <div className='klickly-testimonials__item'>
                        <img
                            src={anese}
                            className='klickly-testimonials__item-image klickly-testimonials__item-image_anese'
                            alt='company logo'
                        />
                        <div className='klickly-testimonials__item-text'>
                            “I love Klickly. They enable brands to immediately stop wasting money on worthless CPM media
                            buys and focus on driving real revenue and lowering my CPA.”
                        </div>
                    </div>
                    <div className='klickly-testimonials__item'>
                        <img
                            src={bloom}
                            className='klickly-testimonials__item-image klickly-testimonials__item-image_bloom'
                            alt='company logo'
                        />
                        <div className='klickly-testimonials__item-text'>
                            “I appreciate Klickly’s regular check-ins and have been pleased with the performance we’re
                            seeing from Klickly ... Setup was quick and didn’t take much of my time which is important.”
                        </div>
                    </div>
                    <div className='klickly-testimonials__item'>
                        <img
                            src={vegamour}
                            className='klickly-testimonials__item-image klickly-testimonials__item-image_vegamour'
                            alt='company logo'
                        />
                        <div className='klickly-testimonials__item-text'>
                            “We have been more than pleased with performance. Klickly continues to meet our
                            expectations! Setup was seamless and love the commission-based model, makes it easy to …get
                            the most ROAS possible. I appreciate Klickly’s regular guidance with strategy when needed.”
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Testimonials;
