import React from 'react';
import nutrition from '../../../../images/main/companies/310.png';
import anese from '../../../../images/main/companies/anese.svg';
import sigmatic from '../../../../images/main/companies/four-sigmatic.png';
import keto from '../../../../images/main/companies/kiss-my-keto.svg';
import utz from '../../../../images/main/companies/utz.svg';
import vegamour from '../../../../images/main/companies/vegamour.svg';

export const cards = [
    {
        id: 'anese',
        name: 'anese',
        image: anese,
        text: (
            <div className='klickly-brands-cards__card-text'>
                Averaged <span className='pink'>4x returns</span> across thousands of orders in 2020
            </div>
        )
    },
    {
        id: 'four-sigmatic',
        name: 'Four Sigmatic',
        image: sigmatic,
        text: (
            <div className='klickly-brands-cards__card-text'>
                Saw a <span className='pink'>&gt;50% increase in quarterly rev’s</span> since launch
            </div>
        )
    },
    {
        id: 'vegamour',
        name: 'Vegamour',
        image: vegamour,
        text: (
            <div className='klickly-brands-cards__card-text'>
                Added <span className='pink'>&gt;$10M in quarterly revenue</span> over 12-months
            </div>
        )
    },
    {
        id: 'utz',
        name: 'UTZ',
        image: utz,
        text: (
            <div className='klickly-brands-cards__card-text'>
                Achieved <span className='pink'>50% new customer sales</span>
            </div>
        )
    },
    {
        id: '310',
        name: '310 Nutrition',
        image: nutrition,
        text: (
            <div className='klickly-brands-cards__card-text'>
                Achieved an <span className='pink'>80% new customer sales</span> rate
            </div>
        )
    },
    {
        id: 'kiss-my-keto',
        name: 'kiss my keto',
        image: keto,
        text: (
            <div className='klickly-brands-cards__card-text'>
                Achieved a <span className='pink'>320% ROI</span> over 1st 6 months with Klickly
            </div>
        )
    }
];
