import React from 'react';
import classnames from 'classnames';
import './index.scss';

const Button = ({ onClick, showIcon = true, text, disabled = false, color = '' }) => {
    const colorClassname = color ? `klickly-button_color-${color}` : '';
    return (
        <button
            onClick={onClick}
            className={classnames('klickly-button', colorClassname, { 'klickly-button_disabled': disabled })}
        >
            {showIcon ? <span className='klickly-button__icon' /> : null}
            {text}
        </button>
    );
};

export default Button;
