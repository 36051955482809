import * as React from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import GradientBackground from '../../shared/components/GradientBackground';
import Footer from '../MainFooter';
import MainHeader from '../MainHeader';
import BetaTerms from './components/BetaTerms';
import DisclosureSensitiveInformation from './components/DisclosureSensitiveInformation';
import OptOut from './components/OptOut';
import PrivacyPage from './components/Privacy';
import TermsPage from './components/Terms';
import './index.scss';

const TermsAndPrivacyPage = () => (
    <div className='k-outer-wrapper terms'>
        <MainHeader showLinks showAuth />
        <div className='privacy-main-wrapper'>
            <GradientBackground />
            <div className='terms-privacy__content'>
                <div className='terms-privacy__content__left'>
                    <ul className='terms-privacy__links'>
                        <li key='betaterms' className='terms-privacy__item'>
                            <NavLink
                                to='/docs/betaterms'
                                activeClassName='is-active'
                                className='terms-privacy__item__link'
                            >
                                Beta Terms
                            </NavLink>
                        </li>
                        <li key='terms' className='terms-privacy__item'>
                            <NavLink to='/docs/terms' activeClassName='is-active' className='terms-privacy__item__link'>
                                Terms & Conditions
                            </NavLink>
                        </li>
                        <li key='privacy' className='terms-privacy__item'>
                            <NavLink
                                to='/docs/privacy'
                                exact
                                activeClassName='is-active'
                                className='terms-privacy__item__link'
                            >
                                Privacy Policy
                            </NavLink>
                        </li>
                        <li key='opt_out' className='terms-privacy__item'>
                            <NavLink
                                to='/docs/privacy/opt_out'
                                activeClassName='is-active'
                                className='terms-privacy__item__link'
                            >
                                Right to Opt Out of Sale of Information
                            </NavLink>
                        </li>
                        <li key='opt_out' className='terms-privacy__item'>
                            <NavLink
                                to='/docs/disclosure-sensitive-info'
                                activeClassName='is-active'
                                className='terms-privacy__item__link'
                            >
                                Right to Limit Use/Disclosure of Sensitive Information
                            </NavLink>
                        </li>
                    </ul>
                </div>

                <div className='terms-privacy__content__right'>
                    <Switch>
                        <Route path='/docs/betaterms' component={BetaTerms} />
                        <Route path='/docs/terms' component={TermsPage} />
                        <Route exact path='/docs/privacy' component={PrivacyPage} />
                        <Route path='/docs/privacy/opt_out' component={OptOut} />
                        <Route path='/docs/disclosure-sensitive-info' component={DisclosureSensitiveInformation} />
                    </Switch>
                </div>
            </div>
        </div>

        <Footer />
    </div>
);

export default TermsAndPrivacyPage;
