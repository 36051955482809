import React, { useState } from 'react';
import classnames from 'classnames';
import { Q4_PARTNERS } from '../../../../constants/q4partners';
import './index.scss';

const Header = ({ myRefs, showForm, menuName }) => {
    const [isOpenMenu, setIsOpenMenu] = useState(false);

    const scrollToPartner = (index) => {
        if (myRefs && myRefs[index] && myRefs[index].current) {
            setIsOpenMenu(!isOpenMenu);
            myRefs[index].current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    /* eslint-disable */
    return (
        <header className='header'>
            <div className='header__content'>
                <div className='header__top'>
                    <div className='dropdown-menu__wrapper'>
                        <div onClick={() => setIsOpenMenu(!isOpenMenu)} className='dropdown-menu__title'>
                            Category <span className='dropdown-menu__title_arrow' />
                        </div>
                        <div className={classnames('dropdown-menu__content', { 'is-opened': isOpenMenu })}>
                            <ul className='dropdown-menu__list-wrapper'>
                                {Q4_PARTNERS.map((props, index) => (
                                    <li
                                        onClick={() => scrollToPartner(index)}
                                        className='dropdown-menu__list-item'
                                        key={index}
                                    >
                                        {props.menuName}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div onClick={showForm} className='header__ecommerce-checklist'>
                        Get my eCommerce BFCM checklist
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
