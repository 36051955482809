import React from 'react';
import GradientBackground from '../../../../shared/components/GradientBackground';
import './index.scss';

const OurPlatformSection = () => (
    <div className='klickly-our-platform-wrapper'>
        <GradientBackground />
        <div className='klickly-our-platform'>
            <div className='klickly-our-platform__content'>
                <div className='klickly-our-platform__title'>
                    Our <br /> Platform
                </div>
                <div className='klickly-our-platform__text'>
                    Top, trail-blazing brands use Klickly’s data-driven <br /> commerce platform to achieve breakthrough
                    results.
                </div>
            </div>
        </div>
    </div>
);
export default OurPlatformSection;
