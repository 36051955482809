import React, { useEffect, useRef } from 'react';
import './index.scss';

const CoverV = () => {
    const video1Ref = useRef(null);
    const video2Ref = useRef(null);

    useEffect(() => {
        video1Ref.current.play();
        video2Ref.current.play();
    }, [video1Ref, video2Ref]);

    return (
        <div>
            <div className='spin-container' id='desktoponly'>
                <div className='spin-main' id='desktoponly'>
                    <div className='widget' id='desktoponly'>
                        <div
                            id='widget'
                            style={{ height: '600px', width: '300px', margin: '0 auto' }}
                            className='desktop-only'
                        >
                            <video
                                ref={video1Ref}
                                id='video1'
                                name='desktop-large'
                                style={{ width: '300px', height: '600px' }}
                                className='responsive-video'
                                autoPlay
                                loop
                                muted
                            >
                                <source
                                    src='https://s3.amazonaws.com/klickly/demo-videos/Cover+600.mp4'
                                    type='video/mp4'
                                />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                    <div className='bgimg' />
                </div>
            </div>

            <div className='spin-container_mobile' id='mobileonly'>
                <div className='spin-main_mobile' id='mobileonly'>
                    <div className='widget_mobile' id='mobileonly'>
                        <div style={{ height: '250px', width: '300px' }} className='mobile-only'>
                            <video
                                ref={video2Ref}
                                id='video2'
                                name='mobile'
                                style={{ width: '300px', height: '250px' }}
                                className='responsive-video'
                                autoPlay
                                loop
                                muted
                            >
                                <source
                                    src='https://s3.amazonaws.com/klickly/demo-videos/cover+250+(mobile).mp4'
                                    type='video/mp4'
                                />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                    <div className='bgimgmobile' />
                </div>
            </div>
        </div>
    );
};

export default CoverV;
