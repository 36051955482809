import React from 'react';
import Footer from '../Footer';
import Header from '../Header';
import TeamList from './components/TeamList';
import './index.scss';

/* eslint-disable */
const TeamPage = () => (
    <div className='team-page'>
        <Header />
        <main className='team-page__content'>
            <h3 className='team-page__title'>Team</h3>
            <p className='team-page__summary'>
                Our team brings a wealth of experience from some of the world's most formidable eCommerce, payments, and
                technology companies
            </p>
            <TeamList />
        </main>
        <Footer />
    </div>
);
export default TeamPage;
