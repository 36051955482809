import React from 'react';
import unifiedDataPlatform from '../../../../images/main/unified-data-platform.png';
import GradientBackground from '../../../../shared/components/GradientBackground';
import Button from '../../../ui/Button';
import './index.scss';

/* eslint-disable */
const SuperchargedCommerceSection = ({ isMobileView }) => {
    const handleClick = () => {
        window.open('/platform?scrollTo=supercharged', '_self');
    };

    const imageSRC = !isMobileView ? unifiedDataPlatform : unifiedDataPlatform;

    return (
        <div className='klickly-supercharged-wrapper'>
            <GradientBackground gradientType='orange' direction='left' />
            <div className='klickly-supercharged'>
                <div className='klickly-supercharged__content'>
                    <div className='klickly-supercharged__left'>
                        <div className='klickly-supercharged__head'>Unified data platform</div>
                        <div className='klickly-supercharged__title'>
                            A fully integrated <br/>
                            suite of real-time, <br/>
                            evergreen data to <br/>
                            help brands compete
                        </div>
                        <div className='klickly-supercharged__subtitle'>
                            Our proprietary, full-funnel consumer data engine leverages AI and billions of events, <br/>
                            mapped to millions of consumers, to unlock commerce insights and revenue for brands.
                        </div>
                        <Button onClick={handleClick} text='Learn More' />
                    </div>
                    <div className='klickly-supercharged__right'>
                        <img src={imageSRC} className='klickly-supercharged__image' alt='Data-driven distributed commerce' />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SuperchargedCommerceSection;
