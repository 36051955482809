import * as React from 'react';
import spinner from '../../../../images/svg/klickly-spinner.svg';
import './index.scss';

const Spinner = ({ size = '250px' }) => {
    return (
        <div className='landing-spinner'>
            <img width={size} className='landing-spinner-img' src={spinner} alt='spinner' />
        </div>
    );
};

export default Spinner;
