import { types } from 'mobx-state-tree';
import { ModalSore } from './ModalSore';

export const RootStore = types
    .model('RootStore', {
        isMobileView: false,
        modalStore: types.optional(ModalSore, {
            errors: { name: '', email: '', phone: '', typeOfCompany: '', subject: '', message: '' }
        })
    })
    .views((self) => ({}))
    .actions((self) => {
        const checkIsMobileView = () => {
            const clientWidth = document.body.clientWidth;
            self.isMobileView = clientWidth <= 667;
        };

        return { checkIsMobileView };
    });
