import React from 'react';
import consumersNew from '../../../../images/main/icons/consumers-new.svg';
import gaugeNew from '../../../../images/main/icons/gauge-new.svg';
import networkNew from '../../../../images/main/icons/network-new.svg';
import './index.scss';

const WePowerSection = () => (
    <div className='klickly-we-power'>
        <div className='klickly-we-power__content'>
            <div className='klickly-we-power__text'>We power brands’:</div>
            <div className='klickly-we-power__items'>
                <div className='klickly-we-power__item'>
                    <img
                        src={consumersNew}
                        className='klickly-we-power__item-icon klickly-we-power__item-icon_consumers'
                        alt='consumers icon'
                    />
                    <div className='klickly-we-power__item-right'>
                        <div className='klickly-we-power__item-title'>Lead identification</div>
                        <div className='klickly-we-power__item-text'>
                            Our proprietary data engine predicts both who will buy and when they’re ready to purchase,
                            allowing you to target the right customers at the right time.
                        </div>
                    </div>
                </div>
                <div className='klickly-we-power__item'>
                    <img
                        src={gaugeNew}
                        className='klickly-we-power__item-icon klickly-we-power__item-icon_gauge'
                        alt='consumers icon'
                    />
                    <div className='klickly-we-power__item-right'>
                        <div className='klickly-we-power__item-title'>Offer prioritization</div>
                        <div className='klickly-we-power__item-text'>
                            Klickly’s AI prioritizes your brands’ products, powering dynamic, eCommerce-enabled offers
                            that are designed to drive conversions and maximize revenue.
                        </div>
                    </div>
                </div>
                <div className='klickly-we-power__item'>
                    <img
                        src={networkNew}
                        className='klickly-we-power__item-icon klickly-we-power__item-icon_network'
                        alt='consumers icon'
                    />
                    <div className='klickly-we-power__item-right'>
                        <div className='klickly-we-power__item-title'>Omni-channel transactions</div>
                        <div className='klickly-we-power__item-text'>
                            Our patented payments tech allows customers to buy from your ads and checkouts on over 25
                            million premium destinations, making it easy to reach target audiences on multiple channels.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
export default WePowerSection;
