import React from 'react';
import { MEMBER_TEAM } from '../../../../constants/memberTeam';
import TeamMember from '../TeamMember';
import './index.scss';

const TeamList = () => {
    const teamSortedByName = [...MEMBER_TEAM].sort((a, b) => a.name.localeCompare(b.name));
    return (
        <div className='team-list'>
            {teamSortedByName.map((member, key) => (
                <TeamMember key={key} member={member} />
            ))}
        </div>
    );
};
export default TeamList;
