import React from 'react';
import '../../index.scss';

/* eslint-disable */
const BetaTerms = () => (
    <div className="privacy-main-wrapper">
        <div className='privacy-wrapper privacy'>
            <div className='privacy-inner-wrapper'>
                <div className='privacy-mid-area'>
                    <div className='privacy-list-head'>
                        <div className='privacy-list-head-left'>
                            <h1 className='privacy-list-head-left__title'>Beta Program - Terms of Use</h1>
                        </div>
                        <div className='privacy-list-head-right'>
                            <p>Last Updated May 28, 2021</p>
                        </div>
                    </div>

                    <p>
                        THIS BETA PROGRAM IS OFFERED BY KLICKLY, INC. AND ITS AFFILIATES ("KLICKLY") AND PARTICIPATION IN
                        ANY BETA PROGRAM IS GOVERNED BY THESE TERMS OF USE (THE "TERMS"). THE BETA PROGRAM MAY CONTAIN ONE
                        OR MORE BETA SERVICES (THE “SERVICES”) AS DETERMINED BY KLICKLY IN ITS SOLE DISCRETION. IN ORDER TO
                        PARTICIPATE IN THE BETA PROGRAM AND USE THE SERVICES, YOU MUST BE SUBJECT TO THESE TERMS AND
                        KLICKLY’S TERMS OF USE FOR THE DURATION OF THE BETA PROGRAM. YOU UNDERSTAND AND AGREE THAT KLICKLY
                        WILL TREAT YOUR USE OF THE SERVICES AS ACCEPTANCE OF THE TERMS. AS LONG AS YOU COMPLY WITH THESE
                        TERMS, KLICKLY GRANTS YOU A PERSONAL, NON-EXCLUSIVE, NON-TRANSFERABLE, LIMITED PRIVILEGE TO ACCESS
                        AND USE THE SERVICES.
                    </p>
                    <p>
                        You also hereby acknowledge that Klickly has not made any representations, promises or guarantees
                        that the Services will ever be announced or made available to anyone in the future and that Klickly
                        has no express or implied obligation to you to announce or introduce the Services. Klickly may
                        discontinue any Beta Program or Beta Service at any time, in its sole discretion, with or without
                        notice. With respect to the Services, this subsection shall supersede any other terms and conditions
                        contained herein, but only to the extent necessary to resolve conflict. The Klickly Terms of Use and
                        the Klickly Privacy Policy located at <a href='/docs/terms'>https://klickly.com/docs/terms</a> are
                        incorporated by reference herein and control for any term not specifically addressed in these Terms.
                    </p>
                    <p>
                        <strong>Services</strong>
                    </p>
                    <p>
                        <strong className='legal-page-content__point'>1.1. Description. </strong>
                        The Services may be a separate, stand-alone service accessible apart from the generally available
                        service to which you subscribe, or it may be a feature or functionality of that service. Further
                        description of the Service is provided at the time you enter the Beta Program, or when a Service is
                        added to the Beta Program, and may be updated from time to time by Klickly as the Beta Program
                        progresses (the "Documentation").
                    </p>
                    <p>
                        <strong className='legal-page-content__point'>1.2. "As Is" Status. </strong>
                        By accepting these terms or using the Services, you understand and acknowledge that the Services are
                        being provided as a "Beta" version and made available on an “As Is” or “As Available” basis. The
                        Services may contain bugs, errors, and other problems. Klickly strongly recommends that you back up
                        all data and information prior to using the Services. YOU ASSUME ALL RISKS AND ALL COSTS ASSOCIATED
                        WITH YOUR USE OF THE SERVICES, INCLUDING, WITHOUT LIMITATION, ANY INTERNET ACCESS FEES, BACK-UP
                        EXPENSES, COSTS INCURRED FOR THE USE OF YOUR DEVICE AND PERIPHERALS, AND ANY DAMAGE TO ANY
                        EQUIPMENT, SOFTWARE, INFORMATION OR DATA. In addition, Klickly is not obligated to provide any
                        maintenance, technical or other support for the Services.
                    </p>
                    <p>
                        <strong className='legal-page-content__point'>1.3. Feedback. </strong>
                        The Services are made available to you for purposes of evaluation and feedback without any
                        compensation or reimbursement of any kind from Klickly. You acknowledge the importance of
                        communication between Klickly and you during your use of the Services and hereby agree to receive
                        related correspondence and updates from Klickly. In the event you request to opt out from such
                        communications, your participation in the Beta Program will be canceled. During the Beta Program,
                        you will be asked to provide feedback regarding your use of the Services. You acknowledge that
                        Klickly owns any feedback provided, and you hereby grant to Klickly, if for any reason it is further
                        needed, a perpetual, non-revocable, royalty-free worldwide license to use and/or incorporate such
                        feedback into any Klickly product or service (including the Services) at any time at the sole
                        discretion of Klickly. Klickly will not publish feedback in a way that is attributable to you
                        without your consent.
                    </p>
                    <p>
                        <strong className='legal-page-content__point'>1.4. Limitations on Use. </strong>
                        Limitations specific to any Service may be contained in the Documentation. You may use the Service
                        pursuant to these terms during any period when the Beta Program is live. When a Beta Program is not
                        live, you will no longer have access to the Services. Klickly reserves the right to modify or impose
                        any limitations on the use of the Services at any time, with or without notice to you.
                    </p>
                    <br />
                    <p>
                        <strong>Participation in the Beta Program.</strong>
                    </p>
                    <p>
                        <strong className='legal-page-content__point'>2.1. Eligibility. </strong>
                        You may participate in the Beta Program upon request and approval by Klickly. In the event that you
                        cease to be a customer of Klickly, participation in the Beta Program will be terminated immediately.
                    </p>
                    <p>
                        <strong className='legal-page-content__point'>2.2. Klickly’s Terms of Use Control. </strong>
                        Unless specifically modified by these Terms, the provisions of Klickly’s Terms of Use continue in
                        full force with regard to your use of the generally available services and these Services, including
                        provisions regarding account activity, password security, user content and security breaches.
                    </p>
                    <p>
                        <strong className='legal-page-content__point'>3. Intellectual Property. </strong>
                        You agree that Klickly owns all legal right, title and interest in and to the Beta Program and the
                        Services, including all intellectual property rights, and except for the license provided herein, no
                        other rights or permissions to any of the Services is granted. Nothing herein gives you a right to
                        use any of Klickly trade names, trademarks, service marks, logos, domain names, and other
                        distinctive brand features.
                    </p>
                    <p>
                        <strong className='legal-page-content__point'>
                            4. Modification and Termination of the Service.{' '}
                        </strong>
                        Klickly reserves the right to modify or terminate the Beta Program or the Services, or your use of
                        the Beta Program or the Services, to limit or deny access to the Services and/or participation in
                        the Beta Program, at any time, in its sole discretion, for any reason, with or without notice and
                        without liability to you. You may discontinue your use of the Services at any time.
                    </p>
                    <p>
                        <strong className='legal-page-content__point'>5. Changes to the Terms. </strong>
                        Klickly reserves the right to make changes to the Terms from time to time. If Klickly changes the
                        Terms, Klickly will provide notice of such changes by posting the amended Terms on the Beta Program
                        website. The amended Terms shall be deemed effective immediately upon posting to the Beta Program
                        website and you understand and agree that if you use the Services after the date on which the Terms
                        have changed, Klickly will treat your use as acceptance of the updated Terms.
                    </p>
                    <p>
                        <strong className='legal-page-content__point'>6. International Use. </strong>
                        If you choose to access the Services from locations other than the United States, you do so on your
                        own initiative and at your own risk and you are responsible for compliance with all relevant laws
                        and regulations. Klickly does not guarantee that the Services shall be appropriate and/or available
                        for use in any particular location.
                    </p>
                </div>
            </div>
        </div>
    </div>
);

export default BetaTerms;
