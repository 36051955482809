import alexT from '../images/member/alex-t-min.png';
import alexZ from '../images/member/alex-z-min.png';
import artem from '../images/member/artem-min.png';
import austin from '../images/member/austin-min.png';
import bradley from '../images/member/bradley-min.png';
import christiana from '../images/member/christiana-min.png';
import cooper from '../images/member/cooper-min.png';
import daren from '../images/member/daren-min.png';
import eugene from '../images/member/eugene-min.png';
import grace from '../images/member/grace-min.png';
import idean from '../images/member/idean-min.png';
import johnson from '../images/member/johnson-min.png';
import natali from '../images/member/natali-min.png';
import noah from '../images/member/noah-min.png';
import oleg from '../images/member/oleg-min.png';
import rob from '../images/member/rob-min.png';
import xavier from '../images/member/xavier-min.png';
import yaroslava from '../images/member/yaroslava-min.png';
import yurii from '../images/member/yurii-min.png';

/* eslint-disable */
export const MEMBER_TEAM = [
    {
        imgName: alexZ,
        name: 'Alex',
        position: 'Tech Lead',
        bio: 'Senior computer scientist with 12+ years in software. Expertise in Node, javascript, infrastructure, and scalability.',
        funFact: ''
    },
    {
        imgName: bradley,
        name: 'Bradley',
        position: 'Sales and Marketing Specialist',
        bio: 'Communications professional with 8 years experience in results-oriented marketing and content.',
        funFact: ''
    },
    {
        imgName: cooper,
        name: 'Cooper',
        position: 'CEO',
        bio: 'Founder, speaker, technologist. Google\'s "Young Innovator," L\'Oreal\'s "Digital Woman of the Year" nominee, Adobe’s "Top Thought-Leader" at Cannes Lions.',
        funFact: ''
    },
    {
        imgName: grace,
        name: 'Grace',
        position: 'Designer',
        bio: 'Graphic Designer at Ogilvy & Mather Advertising. 10+ years experience in print, product and digital design.',
        funFact: ''
    },
    {
        imgName: eugene,
        name: 'Eugene',
        position: 'Software Engineer',
        bio: 'Senior, full-stack software engineer with a passion for tackling challenging technology tasks.',
        funFact: ''
    },
    {
        imgName: natali,
        name: 'Natalia',
        position: 'QA Specialist',
        bio: 'Software and product QA expert. Doubled QA process speed by implementing automated tests.',
        funFact: ''
    },
    {
        imgName: noah,
        name: 'Noah',
        position: 'Software Engineer',
        bio: 'Full stack web developer and ad-ops specialist with over 12 years experience in technical problem solving.',
        funFact: ''
    },
    {
        imgName: oleg,
        name: 'Oleg',
        position: 'Software Engineer',
        bio: 'Backend specialist with 5+ years of experience in building APIs and servers for various projects.',
        funFact: ''
    },
    {
        imgName: daren,
        name: 'Daren',
        position: 'Director of Accounts',
        bio: '10+ years experience specializing in marketing strategy and sales for top Fortune 500 companies.',
        funFact: ''
    },
    {
        imgName: rob,
        name: 'Rob',
        position: 'Operations Manager',
        bio: 'Startup operations professional. Brings ops experience from TechStars, Maker Studios, and Salesforce.',
        funFact: ''
    },
    {
        imgName: christiana,
        name: 'Christiana',
        position: 'Accounts Specialist',
        bio: 'Accounts Specialist with 7+ years driving results-oriented relationships with major accounts (e.g. Bloomingdale\'s, Shopbop / Amazon, Nordstrom).',
        funFact: ''
    },
    {
        imgName: xavier,
        name: 'Xavier',
        position: 'COO',
        bio: 'Ex-Bain with expertise in data analytics and growth strategy. Masters and PhD studies in Organizational Psychology.',
        funFact: ''
    },
    {
        imgName: yaroslava,
        name: 'Yaroslava',
        position: 'QA Specialist',
        bio: 'Quality assurance engineer specialized in eCommerce platforms. Searching for bugs 24/7.',
        funFact: ''
    },
    {
        imgName: artem,
        name: 'Artem',
        position: 'Product Manager',
        bio: 'Product manager with expertise in eCommerce and SaaS. Passionate about data viz.',
        funFact: ''
    },
    {
        imgName: alexT,
        name: 'Alex',
        position: 'QA Lead',
        bio: 'Quality Assurance specialist with rich expertise in improving the quality of software and tech processes.',
        funFact: ''
    },
    {
        imgName: austin,
        name: 'Austin',
        position: 'Performance Marketer',
        bio: 'Highly motivated Performance Marketing Specialist focused on humanizing the digital buying experience.',
        funFact: ''
    },
    {
        imgName: idean,
        name: 'Idean',
        position: 'Sales Specialist',
        bio: 'Sales Specialist with 5+ years of experience in sales, account management, and online advertising. Expert in creating growth and scaling solutions for small businesses to Fortune 500 companies.',
        funFact: ''
    },
    {
        imgName: johnson,
        name: 'Johnson',
        position: 'Performance Marketer',
        bio: 'Performance marketing specialist with 10+ years of experience in online marketing with a focus on e-commerce and user acquisition.',
        funFact: ''
    },
    {
        imgName: yurii,
        name: 'Yurii',
        position: 'Software Engineer',
        bio: 'HTML/Front-end developer with a passion for comfortable and enjoyable user interfaces.',
        funFact: ''
    }
];
