import circleGreen from '../../../../images/main/icons/circle-green.7206d48ad23c73e3bc352f4e840ba2fe.svg';
import configGreen from '../../../../images/main/icons/configure-green.208f2bef59008b98a11a42ce24590247.svg';
import dbGreen from '../../../../images/main/icons/database-green.6080283d429d8214c543401aff5c7093.svg';
import gageGreen from '../../../../images/main/icons/gage-green.cc1002c9b8ec5a68605ad2784c9375a5.svg';
import gridGreen from '../../../../images/main/icons/grid-green.ac92d5eb4c6bd184ce259c7cb0c046b9.svg';
import linkGreen from '../../../../images/main/icons/link-green.9244b9bda47fed638bbcaf74f01ad12c.svg';
import searchGreen from '../../../../images/main/icons/search-green.2faa7dc1874f8e87bca1485cf7ba39cf.svg';
import targetGreen from '../../../../images/main/icons/target-green.8f1d3be3e101ab22798fef904d6bce13.svg';
import twoPeopleGreen from '../../../../images/main/icons/two-people.svg';
export const cards = [
    {
        icon: dbGreen,
        title: 'Rich data sources',
        text: 'Immediately access 1,000s of 1st, 2nd, and 3rd party data sources in real-time'
    },
    {
        icon: linkGreen,
        title: 'Integrative pipeline',
        text: 'Benefit from the extensive cleaning our data receives as its integrated into our pipeline'
    },
    {
        icon: twoPeopleGreen,
        title: 'Identity graph',
        text: 'Leverage our de-duped and normalized records to reduce signal noise'
    },
    {
        icon: circleGreen,
        title: 'Profile Identification',
        text: 'Ensure anonymous buying signals are turned into accurate profile data'
    },
    {
        icon: configGreen,
        title: 'Orchestration',
        text: 'Seamlessly engage the right consumers with the right message at the right time'
    },
    {
        icon: gridGreen,
        title: 'Segmentation',
        text: 'Target dynamically segmented behavioral profiles to maximize returns'
    },
    {
        icon: gageGreen,
        title: 'Analytics',
        text: 'Uncover real account-based insights that help align sales and marketing'
    },
    {
        icon: searchGreen,
        title: 'Prospecting',
        text: 'Connect with the highest priority buyers at the point of consideration'
    },
    {
        icon: targetGreen,
        title: 'Decisioning',
        text: 'Surface the products and offers that convert your prospects to customers'
    }
];
