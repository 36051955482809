import React from 'react';
import { cards } from './cards';
import './index.scss';

const DataCardsSection = () => (
    <div className='klickly-data-cards'>
        <div className='klickly-data-cards-title'>Our data suite includes:</div>
        <div className='klickly-data-cards-content'>
            {cards.map((card) => {
                return (
                    <div className='klickly-data-cards__card' key={card.title}>
                        <div className='klickly-data-cards__card-left'>
                            <img src={card.icon} className='klickly-data-cards__card-icon' alt='card-icon' />
                        </div>
                        <div className='klickly-data-cards__card-right'>
                            <div className='klickly-data-cards__card-title'>{card.title}</div>
                            <div className='klickly-data-cards__card-text'>{card.text}</div>
                        </div>
                    </div>
                );
            })}
        </div>
    </div>
);
export default DataCardsSection;
