import React from 'react';
import './index.scss';

const TeamMember = ({ member }) => {
    const { imgName = '', name = '', position = '', bio = '', funFact = '' } = member;

    return (
        <div className='team-member'>
            <div className='card-img'>
                <img className='card-img__img' src={imgName} alt='' />
                <div className='card-info'>
                    <div className='card-info__line' />
                    <div className='card-info__body'>
                        <p className='card-info__desc'>{bio}</p>
                        <p className='card-info__desc'>{funFact}</p>
                    </div>
                </div>
            </div>
            <div className='team-member__caption'>
                {name} - {position}
            </div>
        </div>
    );
};
export default TeamMember;
