import React from 'react';
import onlyPayForPerformance from '../../images/main/onlyPayForPerformance.png';
import GradientBackground from '../../shared/components/GradientBackground';
import Button from '../ui/Button';
import './index.scss';

const GetStartedSection = ({ targetRef, requestDemoModal, gradientType = 'orange-invert' }) => {
    return (
        <div className='getting-started-wrapper' ref={targetRef}>
            <GradientBackground gradientType={gradientType} direction='left' />
            <div className='getting-started'>
                <div className='getting-started__content'>
                    <div className='getting-started__left'>
                        <div className='getting-started__title'>
                            Ready to <br className='br-mobile' /> Get <br className='br-mobile' />{' '}
                            <br className='br-desktop' /> Started?
                        </div>
                        <div className='getting-started__text'>
                            Klickly’s proprietary, full-funnel consumer data engine unlocks <br /> end-to-end
                            distributed-commerce for brands. Contact us to learn <br /> more about how Klickly can help
                            you compete
                        </div>
                        <Button onClick={requestDemoModal} text='Contact sales' />
                    </div>
                    <div className='getting-started__right'>
                        <img src={onlyPayForPerformance} className='getting-started__image' alt='pricing' />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default GetStartedSection;
