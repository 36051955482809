/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import check from '../../images/svg/check.svg';
import closeIcon from '../../images/svg/close.svg';
import Input from '../ui/Input';
import Textarea from '../ui/TextArea';
import Spinner from './components/Spinner';
import './index.scss';

const RequestDemoModal = inject('store')(
    observer(({ store }) => {
        const { modalDisplay, onChange, errors, setFieldError } = store.modalStore;
        const [values, setValues] = useState({ name: '', email: '', phone: '', typeOfCompany: '', subject: '', message: '' });
        const [showForm, setShowForm] = useState(modalDisplay);
        const ref = useRef(null);

        const clearForm = () => {
            ref.current.reset();
            self.errors = [];
            for (const obj in self.data) {
                self.data[obj] = '';
            }
        };

        useEffect(() => {
            if (modalDisplay !== showForm) {
                clearForm();
                setShowForm(modalDisplay);
            }
        }, [modalDisplay]);

        useEffect(() => {
            if (showForm) return document.body.classList.add('open-modal');
            return document.body.classList.remove('open-modal');
        }, [showForm]);

        const handleOnChange = (value, name) => {
            setValues({ ...values, [name]: value });
            return onChange(value, name);
        }

        return (
            <div className={`landing-page-modal ${store.modalStore.modalDisplay ? 'landing-page-modal--active' : ''}`}>
                {store.modalStore.showSpinner ? <Spinner /> : null}
                {store.modalStore.showSuccess ? (
                    <div className='landing-page-modal-content'>
                        <div className='landing-page-modal-success'>
                            <img src={check} alt='checked' />
                            <div className='landing-page-modal-success__heading'>Email sent.</div>
                            <div className='landing-page-modal-success__text'>
                                Thanks for contacting us - we'll be in touch!
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className='landing-page-modal-header'>
                            <div className='landing-page-modal-title'>
                                <h1>Contact Sales</h1>
                            </div>

                            <div
                                className='landing-page-modal__close'
                                onClick={() => store.modalStore.toggleModal(false)}
                            >
                                <img src={closeIcon} alt='close' />
                            </div>
                        </div>

                        <div className='landing-page-modal-content'>
                            <form ref={ref} className='landing-page-modal-form'>
                                <div className='form-group half to-left'>
                                    <Input
                                        type='text'
                                        name='name'
                                        value={values.name}
                                        onChange={handleOnChange}
                                        label='Name'
                                        errorMessage={errors.name}
                                    />
                                </div>
                                <div className='form-group half to-right'>
                                    <Input
                                        type='text'
                                        name='email'
                                        value={values.email}
                                        onChange={handleOnChange}
                                        label='Email'
                                        errorMessage={errors.email}
                                    />
                                </div>
                                <div className='form-group full'>
                                    <Input
                                        type='text'
                                        name='phone'
                                        value={values.phone}
                                        onChange={handleOnChange}
                                        label='Phone'
                                        errorMessage={errors.phone}
                                    />
                                </div>
                                <div className='form-group full'>
                                    <label className='landing-page-modal__label-for-select'>
                                        {/* onChange for change styles placeholder */}
                                        <select
                                            name='typeOfCompany'
                                            defaultValue={values.typeOfCompany}
                                            onChange={(e) => {
                                                e.target.classList.add('selected');
                                                setFieldError('typeOfCompany', '');
                                            }}
                                            className={`landing-page-modal__select js-landing-page-modal__select ${
                                                errors.typeOfCompany ? 'landing-page-modal__input--error' : ''
                                            }`}
                                        >
                                            <option value='' disabled>
                                                Type of company
                                            </option>
                                            <option value='merchant'>Merchant</option>
                                            <option value='publisher'>Publisher</option>
                                            <option value='other'>Other</option>
                                        </select>
                                        <div className='error-message'>{errors.typeOfCompany}</div>
                                    </label>
                                </div>
                                <div className='form-group full'>
                                    <Input
                                        type='text'
                                        name='subject'
                                        value={values.subject}
                                        onChange={handleOnChange}
                                        label='Subject'
                                        errorMessage={errors.subject}
                                    />
                                </div>
                                <div className='form-group full'>
                                    <Textarea
                                        name='message'
                                        value={values.message}
                                        onChange={handleOnChange}
                                        label='Write a brief message...'
                                        rows={6}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className='landing-page-modal-footer'>
                            <button
                                type='submit'
                                className='landing-page-btn landing-page-btn-submit js-request'
                                onClick={() => store.modalStore.submit(ref.current)}
                            >
                                <span className='landing-page-btn-icon' /> Submit
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    })
);
export default RequestDemoModal;
