import Validator from 'validatorjs';

Validator.register(
    'phone',
    function (value) {
        const phoneNumberLength = value.replace(/[^0-9]/g, '').length;
        return phoneNumberLength === 10;
    },
    'Please input a full phone number.'
);

Validator.register(
    'ageMin',
    function (value, requirement) {
        const currentDate = new Date();
        const inputYear = value.split('-')[2];
        const currentYear = currentDate.getFullYear();
        if (inputYear && currentYear - inputYear < Number(requirement)) {
            return false;
        }
        return true;
    },
    'You are under 18 years old.'
);

Validator.register(
    'ageMax',
    function (value, requirement) {
        const currentDate = new Date();
        const inputYear = value.split('-')[2];
        const currentYear = currentDate.getFullYear();
        if (inputYear && currentYear - inputYear > Number(requirement)) {
            return false;
        }
        return true;
    },
    'Seems like you are older than 100 years.'
);

Validator.register(
    'validSocialLink',
    function (value) {
        const validSocialLinkRule = new RegExp('^(https?://)?(www.)?(facebook|instagram).com/[a-zA-Z0-9(.?)?]');
        return validSocialLinkRule.test(value);
    },
    'Please provide correct link'
);
