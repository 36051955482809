import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Header from '../MainHeader';
import './index.scss';

const PRESS_PAGE_URL = 'https://klickly-press-page.carrd.co/';

const PressPage = observer(() => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => (document.body.style.overflow = 'visible');
    }, []);

    return (
        <div className='press-page'>
            <Header showLinks />
            <main className='press-page__content'>
                <iframe title='press' frameBorder='0' className='press-page__iframe' src={PRESS_PAGE_URL} />
            </main>
        </div>
    );
});
export default PressPage;
