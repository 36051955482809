import React, { useState } from 'react';
import classnames from 'classnames';
import './index.scss';

/* eslint-disable */
const Header = ({ showLinks = true, showAuth = true }) => {
    const [menuVisible, setMenuVisible] = useState(false);
    const handleNavClick = (route, param = '_self') => {
        window.open(route, param);
        setMenuVisible(false);
    };

    const onLogoClick = () => {
        window.open('/', '_self');
    };
    return (
        <header className='klickly-header'>
            <div className='klickly-header__content'>
                <div className='klickly-header__left'>
                    {showLinks && (
                        <div className='klickly-header__menu-icon' onClick={() => setMenuVisible(!menuVisible)} />
                    )}
                    <div onClick={onLogoClick} className='klickly-header__logo' />
                </div>
                {showLinks && (
                    <nav
                        className={classnames('klickly-header__nav-links', {
                            'klickly-header__nav-links_visible': menuVisible
                        })}
                    >
                        <div onClick={() => handleNavClick('/platform')} className='klickly-header__nav-link'>
                            Platform
                        </div>
                        <div
                            onClick={() => handleNavClick('/?scrollTo=pricing')}
                            className='klickly-header__nav-link'
                        >
                            Pricing
                        </div>
                        <div onClick={() => handleNavClick('/customers')} className='klickly-header__nav-link'>
                            Customers
                        </div>
                        <div onClick={() => handleNavClick('/press')} className='klickly-header__nav-link'>
                            Press
                        </div>
                        <a
                            className='klickly-header__nav-link'
                            href='https://blog.klickly.com'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Blog
                        </a>
                    </nav>
                )}
                {showAuth && (
                    <div className='klickly-header__auth'>
                        <a
                            className='klickly-header__login'
                            href='https://brands.klickly.com/page/sign-in'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Log in
                        </a>
                    </div>
                )}
            </div>
        </header>
    );
};
export default Header;
