import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import GetStartedSection from '../GetStartedSection';
import Footer from '../MainFooter';
import Header from '../MainHeader';
import RequestDemoModal from '../RequestDemoModal';
import Testimonials from '../Testimonials';
import BrandsSection from './components/BrandsSection';
import ContactSalesSection from './components/ContactSalesSection';
import HowItWorksSection from './components/HowItWorksSection';
import Metrics from './components/Metrics';
import ObsessedWithSuccess from './components/ObsessedWithSuccess';
import Pricing from './components/Pricing';
import SuperchargedCommerceSection from './components/SuperchargedCommerceSection';
import UnifiedPlatformSection from './components/UnifiedPlatformSection';
import '../../styles/main.scss';

const MainPage = inject('store')(
    observer(({ store, history }) => {
        const { isMobileView } = store;
        const pricing = useRef(null);

        const onScrollTo = (ref) => {
            if (ref) ref.current.scrollIntoView({ behavior: 'smooth' });
        };

        useEffect(() => {
            const scrollTo = new URLSearchParams(history.location.search).get('scrollTo');

            if (scrollTo === 'pricing') {
                onScrollTo(pricing);
                const query = new URLSearchParams(history.location.search);
                query.delete('scrollTo');
                history.replace({ search: query.toString() });
            }
        }, [pricing]);

        return (
            <div className='main-page-wrapper'>
                <Header showLinks />
                <ContactSalesSection
                    isMobileView={isMobileView}
                    requestDemoModal={() => store.modalStore.toggleModal(true)}
                />
                <BrandsSection isMobileView={isMobileView} />
                <SuperchargedCommerceSection isMobileView={isMobileView} />
                <UnifiedPlatformSection isMobileView={isMobileView} />
                <HowItWorksSection />
                <Pricing targetRef={pricing} requestDemoModal={() => store.modalStore.toggleModal(true)} />
                <Testimonials />
                <ObsessedWithSuccess />
                <Metrics />
                <GetStartedSection requestDemoModal={() => store.modalStore.toggleModal(true)} />
                <Footer />

                <div className={`modal-overlay ${store.modalStore.modalDisplay ? 'modal-overlay--active' : ''}`}>
                    <RequestDemoModal />
                </div>
            </div>
        );
    })
);

export default MainPage;
