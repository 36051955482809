import React from 'react';
import './index.scss';

const DisclosureSensitiveInformation = () => (
    <div className='disclosure-sens-info-wrapper'>
        <div className='disclosure-sens-info-head'>
            <div className='disclosure-sens-info-head__left'>
                <h1 className='disclosure-sens-info-head__title'>
                    Right to Limit Use/Disclosure of Sensitive Information
                </h1>
            </div>
            <div className='disclosure-sens-info-head__right'>
                <p className='disclosure-sens-info-head__last-updated'>Last Updated August 10, 2023</p>
            </div>
        </div>
        <div className='disclosure-sens-info-content'>
            <div className='disclosure-sens-info-content__desc'>
                <p>
                    To opt out concerning use and disclosure of your sensitive personal information (as defined under
                    and described in our privacy policy and/or an applicable law), please submit a request by emailing
                    the following info to{' '}
                    <a className='disclosure-sens-info-content__desc_mail' href='mailto:privacy@klickly.com'>
                        privacy@klickly.com
                    </a>
                    .
                </p>
            </div>
            <div className='disclosure-sens-info-content__list-info'>
                <ul className='disclosure-sens-info-content__list-info__items'>
                    <li>First name</li>
                    <li>Last name</li>
                    <li>Email</li>
                    <li>Street Address</li>
                    <li>City</li>
                    <li>State</li>
                    <li>Postal Code</li>
                    <li>Country</li>
                </ul>
            </div>
            <div className='disclosure-sens-info-content__desc'>
                <p>
                    The right to opt-out is subject to certain exemptions and exceptions. Additionally, we may be unable
                    to fulfill your request if we believe the request is fraudulent.
                </p>
            </div>
        </div>
        <div className='disclosure-sens-info-foot'>
            <p>
                Please visit our <a href='/docs/privacy'>Privacy Policy</a> for information about your rights.
            </p>
        </div>
    </div>
);
export default DisclosureSensitiveInformation;
