import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';
import './utils/validators';
import { RootStore } from './_store/RootStore';
import CoverV from './components/coverv';
import Customers from './components/Customers';
import MainPage from './components/MainPage';
import MultiV from './components/multiv';
import Platform from './components/Platform';
import PressPage from './components/PressPage';
import Q4CampaignPage from './components/Q4CampaignPage';
import TeamPage from './components/TeamPage';
import TermsAndPrivacyPage from './components/TermsPrivacy';
import App from './App';
import './styles/index.scss';

const elements = (
    <Provider store={RootStore.create()}>
        <BrowserRouter basename='/'>
            <App>
                <Switch>
                    <Route exact path='/' component={MainPage} />
                    <Route path='/platform' component={Platform} />
                    <Route path='/customers' component={Customers} />

                    <Route path='/team' component={TeamPage} />
                    <Route path='/press' component={PressPage} />
                    <Route path='/docs' component={TermsAndPrivacyPage} />
                    <Route path='/2020BFCM-stronger-together' component={Q4CampaignPage} />

                    <Route path='/coverv' component={CoverV} />
                    <Route path='/multiv' component={MultiV} />

                    <Route path='*' component={MainPage} />
                </Switch>
            </App>
        </BrowserRouter>
    </Provider>
);
createRoot(document.getElementById('root')).render(elements);
