import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Q4_PARTNERS } from '../../../../constants/q4partners';
import './index.scss';

const Footer = ({ myRefs, showForm, menuName }) => {
    const [isOpenMenu, setIsOpenMenu] = useState(false);

    const scrollToPartner = (index) => {
        if (myRefs && myRefs[index] && myRefs[index].current) {
            setIsOpenMenu(!isOpenMenu);
            myRefs[index].current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    /* eslint-disable */
    return (
        <footer className='footer'>
            <div className='footer__content'>
                <div className='footer__top'>
                    <div className='dropdown-menu__wrapper'>
                        <div onClick={() => setIsOpenMenu(!isOpenMenu)} className='dropdown-menu__title'>
                            Jump to category <span className='dropdown-menu__title_arrow' />
                        </div>
                        <div className={classnames('dropdown-menu__content_bottom', { 'is-opened': isOpenMenu })}>
                            <ul className='dropdown-menu__list-wrapper'>
                                {Q4_PARTNERS.map((props, index) => (
                                    <li
                                        onClick={() => scrollToPartner(index)}
                                        className='dropdown-menu__list-item'
                                        key={index}
                                    >
                                        {menuName}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div onClick={showForm} className='header__ecommerce-checklist'>
                        Get my eCommerce BFCM checklist
                    </div>
                </div>
                <div className='k-footer-bottom'>
                    <div className='k-footer-bottom__wrapper'>
                        <p className='k-footer-bottom__text'>&copy; Klickly</p>
                        <div className='k-footer-bottom__links'>
                            <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href='https://blog.klickly.com/'
                                className='k-footer-bottom__text k-footer-bottom__link'
                            >
                                Blog
                            </a>
                            <Link
                                to='/docs/terms'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='k-footer-bottom__text k-footer-bottom__link'
                            >
                                Terms
                            </Link>
                            <Link
                                to='/docs/privacy'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='k-footer-bottom__text k-footer-bottom__link'
                            >
                                Privacy
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
