import React from 'react';
import './index.scss';

const getGradientClaasName = (gradientType) => {
    switch (gradientType) {
        case 'orange':
            return 'bg-gradient-orange';
        case 'orange-invert':
            return 'bg-gradient-orange-invert';
        case 'pink-invert':
            return 'bg-gradient-pink-invert';
        default:
            return 'bg-gradient-pink';
    }
};

const GradientBackground = ({ children, gradientType, direction = 'right', verticalAlign = false }) => {
    return (
        <div className='bg-gradient-wrapper'>
            <div
                className={`bg-gradient ${getGradientClaasName(
                    gradientType
                )} direction-${direction} verticalAlign-${verticalAlign}`}
            >
                {children}
            </div>
        </div>
    );
};

export default GradientBackground;
