import React from 'react';
import './index.scss';

const OptOut = () => (
    <div className='opt-out-wrapper'>
        <div className='opt-out-head'>
            <div className='opt-out-head__left'>
                <h1 className='opt-out-head__title'>Right to Opt Out of Sale of Information</h1>
            </div>
            <div className='opt-out-head__right'>
                <p className='opt-out-head__last-updated'>Last Updated August 10, 2023</p>
            </div>
        </div>
        <div className='opt-out-content'>
            <div className='opt-out-content__desc'>
                <p>
                    To opt out of the sale of your personal information, please submit a request by emailing the
                    following info to <a href='mailto:privacy@klickly.com'>privacy@klickly.com</a>.
                </p>
            </div>
            <div className='opt-out-content__list-info'>
                <ul className='opt-out-content__list-info__items'>
                    <li>First name</li>
                    <li>Last name</li>
                    <li>Email</li>
                    <li>Street Address</li>
                    <li>City</li>
                    <li>State</li>
                    <li>Postal Code</li>
                    <li>Country</li>
                </ul>
            </div>
            <div className='opt-out-content__desc'>
                <p>
                    The right to opt-out is subject to certain exemptions and exceptions. For example, it does not apply
                    to personal information that we share with service providers that perform certain business functions
                    for us pursuant to a written contract. Additionally, we may be unable to fulfill your request if we
                    believe the request is fraudulent. <br />
                    Please visit our <a href='/docs/privacy'>Privacy Policy</a> for additional information about your
                    rights.
                </p>
            </div>
        </div>
    </div>
);
export default OptOut;
