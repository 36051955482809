import React from 'react';
import './index.scss';

const Metrics = ({ targetRef }) => {
    return (
        <div className='klickly-metrics' ref={targetRef}>
            <div className='klickly-metrics__head'>Metrics</div>
            <div className='klickly-metrics__title'>
                The Power <br /> to Help You Scale
            </div>
            <div className='klickly-metrics__text'>
                The depth and breadth of our platform means we’re the most powerful – and easiest – way to drive
                distributed commerce
            </div>
            <div className='klickly-metrics__content'>
                <div className='klickly-metrics__items'>
                    <div className='klickly-metrics__item'>
                        <div className='klickly-metrics__item-amount pink'>10B</div>
                        <div className='klickly-metrics__item-text'>Comprehensive consumer events</div>
                        <div className='klickly-metrics__item-description'>
                            Our platform analyzes over 10 billion full-funnel, comprehensive consumer events to provide
                            you with the insights and AI-driven decisioning you need to succeed.
                        </div>
                    </div>
                    <div className='klickly-metrics__item'>
                        <div className='klickly-metrics__item-amount pink-light'>25M+</div>
                        <div className='klickly-metrics__item-text'>Premium eCommerce destinations</div>
                        <div className='klickly-metrics__item-description'>
                            Our proprietary payments tech lets customers buy from your ads and checkouts on over 25M
                            premium online destinations, allowing you to reach your target audience everywhere they are.
                        </div>
                    </div>
                    <div className='klickly-metrics__item'>
                        <div className='klickly-metrics__item-amount orange'>$1.2B</div>
                        <div className='klickly-metrics__item-text'>Underlying merchant sales</div>
                        <div className='klickly-metrics__item-description'>
                            Our platform sees underlying merchant sales of over $1.2 billion every year, making our AI
                            the most powerful and easiest way to drive distributed commerce.
                        </div>
                    </div>
                    <div className='klickly-metrics__item'>
                        <div className='klickly-metrics__item-amount yellow'>&#62;15 MIN</div>
                        <div className='klickly-metrics__item-text'>Account set-up and campaign launch</div>
                        <div className='klickly-metrics__item-description'>
                            Our proprietary Open API Integration enables ultra-quick AI-powered onboarding and campaign
                            launch (with no need for costly technical resources), so you can start driving revenue now.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Metrics;
