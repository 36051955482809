import React from 'react';
import consumersNew from '../../../../images/main/icons/consumers-new.svg';
import gaugeNew from '../../../../images/main/icons/gauge-new.svg';
import networkNew from '../../../../images/main/icons/network-new.svg';
import './index.scss';

const HowItWorksSection = ({ targetRef }) => {
    return (
        <div className='klickly-how-works' ref={targetRef}>
            <div className='klickly-how-works__content'>
                <div className='klickly-how-works__head'>How it works</div>
                <div className='klickly-how-works__title'>
                    Smart, <br /> end-to-end distributed commerce
                </div>
                <div className='klickly-how-works__subtitle'>
                    Make smarter, data-driven decisions with our end-to-end, AI-powered distributed commerce solution
                </div>
                <div className='klickly-how-works__items'>
                    <div className='klickly-how-works__item'>
                        <img
                            src={consumersNew}
                            className='klickly-how-works__item-icon klickly-how-works__item-icon_consumers'
                            alt='consumers icon'
                        />
                        <div className='klickly-how-works__item-title'>Lead identification</div>
                        <div className='klickly-how-works__item-text'>
                            Our proprietary data engine predicts both who will buy and when they’re ready to purchase,
                            allowing you to target the right customers at the right time.
                        </div>
                    </div>
                    <div className='klickly-how-works__item'>
                        <img
                            src={gaugeNew}
                            className='klickly-how-works__item-icon klickly-how-works__item-icon_gauge'
                            alt='gauge icon'
                        />
                        <div className='klickly-how-works__item-title'>Offer prioritization</div>
                        <div className='klickly-how-works__item-text'>
                            Klickly’s AI prioritizes your brands’ products, powering dynamic, eCommerce-enabled offers
                            that are designed to drive conversions and maximize revenue.
                        </div>
                    </div>
                    <div className='klickly-how-works__item'>
                        <img
                            src={networkNew}
                            className='klickly-how-works__item-icon klickly-how-works__item-icon_network'
                            alt='network icon'
                        />
                        <div className='klickly-how-works__item-title'>Omni-channel transactions</div>
                        <div className='klickly-how-works__item-text'>
                            Our patented payments tech allows customers to buy from your ads and checkouts on over 25
                            million premium destinations, making it easy to reach target audiences on multiple channels.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default HowItWorksSection;
