import React from 'react';
import { inject, observer } from 'mobx-react';
import './index.scss';

const BrandsSection = inject('store')(
    observer(({ store }) => (
        <div className='klickly-brands'>
            <div className='klickly-brands__content'>
                <div className='klickly-brands__entrepreneur' />
                <div className='klickly-brands__forbes' />
                <div className='klickly-brands__huffpost' />
                <div className='klickly-brands__inc' />
                <div className='klickly-brands__mashable' />
                <div className='klickly-brands__usa-today' />
                {store.isMobileView && (
                    <React.Fragment>
                        <div className='klickly-brands__entrepreneur' />
                        <div className='klickly-brands__forbes' />
                        <div className='klickly-brands__huffpost' />
                        <div className='klickly-brands__inc' />
                        <div className='klickly-brands__mashable' />
                        <div className='klickly-brands__usa-today' />
                    </React.Fragment>
                )}
            </div>
        </div>
    ))
);
export default BrandsSection;
