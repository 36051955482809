import React from 'react';
import obsessed from '../../../../images/main/obsessed.png';
import GradientBackground from '../../../../shared/components/GradientBackground';
import './index.scss';

const ObsessedWithSuccess = () => {
    return (
        <div className='klickly-obsessed-wrapper'>
            <GradientBackground gradientType='pink-invert' />
            <div className='klickly-obsessed'>
                <div className='klickly-obsessed__content'>
                    <div className='klickly-obsessed__left'>
                        <img
                            src={obsessed}
                            className='klickly-obsessed__image'
                            alt='Data-driven distributed commerce'
                        />
                    </div>
                    <div className='klickly-obsessed__right'>
                        <div className='klickly-obsessed__title'>
                            Obsessed with <br /> your success
                        </div>
                        <div className='klickly-obsessed__subtitle'>
                            New to AI-powered data and acquisition platforms? Klickly Customer <br /> Success Experts
                            are here to help you program, analyze, fine-tune, and <br /> scale your account settings —
                            supercharging your growth, targeting,
                            <br /> and acquisition strategy.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ObsessedWithSuccess;
