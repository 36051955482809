import React from 'react';
import classNames from 'classnames';
import './index.scss';

const Input = ({ type, value, placeholder, label, name, id, additionalClassname, errorMessage, onChange }) => {
    const handleInputChange = (data) => {
        onChange(data.target.value, name);
    };
    return (
        <div
            className={classNames('klickly-input-wrapper', additionalClassname, {
                'input-with-error': Boolean(errorMessage)
            })}
        >
            <input
                className='klickly-input'
                type={type}
                id={id || name}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={handleInputChange}
            />

            {label ? (
                <label htmlFor={id || name} className='klickly-input__label'>
                    <span
                        className={classNames('klickly-input__label-text', {
                            'klickly-input__label-text_on-top': Boolean(value)
                        })}
                    >
                        {label}
                    </span>
                </label>
            ) : null}

            <div className='error-message'>{errorMessage || ' '}</div>
        </div>
    );
};

export default Input;
