import React from 'react';
import onlyPayForPerformance from '../../../../images/main/onlyPayForPerformance.png';
import GradientBackground from '../../../../shared/components/GradientBackground';
import Button from '../../../ui/Button';
import './index.scss';

const Pricing = ({ targetRef, requestDemoModal }) => {
    return (
        <div className='klickly-pricing-wrapper' ref={targetRef}>
            <GradientBackground gradientType='orange-invert' direction='left' />
            <div className='klickly-pricing'>
                <div className='klickly-pricing__content'>
                    <div className='klickly-pricing__left'>
                        <div className='klickly-pricing__title'>
                            Only pay for <br /> performance
                        </div>
                        <div className='klickly-pricing__text'>
                            We are the only data platform that lets you choose your own <br /> commission (and pay only
                            on success). Why? Because we can. We <br /> have game-changing proprietary data and AI. So,
                            unlike other <br /> platforms, we put our money where our mouth is and only charge <br />{' '}
                            when we do what we promise: drive sales.
                        </div>
                        <Button onClick={requestDemoModal} text='Contact sales' />
                    </div>
                    <div className='klickly-pricing__right'>
                        <img src={onlyPayForPerformance} className='klickly-pricing__image' alt='pricing' />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Pricing;
