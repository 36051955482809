import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer__content'>
                <div className='k-footer-bottom'>
                    <div className='k-footer-bottom__wrapper'>
                        <p className='k-footer-bottom__text'>&copy; Klickly</p>
                        <div className='k-footer-bottom__links'>
                            <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href='https://blog.klickly.com/'
                                className='k-footer-bottom__text k-footer-bottom__link'
                            >
                                Blog
                            </a>
                            <Link
                                to='/docs/terms'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='k-footer-bottom__text k-footer-bottom__link'
                            >
                                Terms
                            </Link>
                            <Link
                                to='/docs/privacy'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='k-footer-bottom__text k-footer-bottom__link'
                            >
                                Privacy
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default Footer;
