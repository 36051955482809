import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { Q4_PARTNERS } from '../../constants/q4partners';
import Footer from './components/Footer';
import Header from './components/Header';
import Partner from './components/Partner';
import './index.scss';

/* eslint-disable */

const Q4CampaignPage = inject('store')(
    observer(({ store }) => {
        const [isHiddenForm, setIsHiddenForm] = useState(true);
        const [iframeSize, setIframeSize] = useState({ width: '800', height: '500' });
        const clickpostRef = useRef(null);
        const feraRef = useRef(null);
        const growaveRef = useRef(null);
        const klicklyRef = useRef(null);
        const recartRef = useRef(null);
        const referralcandyRef = useRef(null);
        const searchaniseRef = useRef(null);
        const smsbumpRef = useRef(null);
        const sufioRef = useRef(null);
        const myRefs = [clickpostRef, feraRef, growaveRef, klicklyRef, recartRef, referralcandyRef, searchaniseRef, smsbumpRef, sufioRef ];

        const formWrapperRef = useRef(null);
        const formRef = useRef(null);

        const scroollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };

        const changeIFrameSize = (width, height) => setIframeSize({ width, height });

        const onResize = () => {
            if (window.innerWidth > 950) {
                changeIFrameSize('800', '500');
            }

            if (window.innerWidth < 950 && window.innerWidth >= 450) {
                changeIFrameSize('600', '500');
            }

            if (window.innerWidth < 450 && window.innerWidth > 400) {
                changeIFrameSize('390', '500');
            }

            if (window.innerWidth <= 400) {
                changeIFrameSize('360', '500');
            }
        };

        useEffect(() => {
            window.addEventListener('resize', onResize);
            return () => {
                window.removeEventListener('resize', onResize);
            };
        }, []);

        return (
            <div className='campaign-page'>
                <Header showForm={() => setIsHiddenForm(false)} myRefs={myRefs} />
                <div className='campaign-page-wrapper'>
                    <div className='campaign-page-content'>
                        <div className='campaign-page-section1'>
                            <div className='campaign-page-section1__title'>
                                BLACK FRIDAY/CYBER MONDAY <br />
                                STRONGER TOGETHER
                            </div>
                            <div className='campaign-page-section1__subtitle'>The best SaaS deals in one location.</div>
                            <div className='campaign-page-section1__logos-wrapper'>
                                <div className='campaign-page-section1__logos-content'>
                                    <div className='campaign-page-section1__logo-wrapper'>
                                        <div className='campaign-page-section1__logo campaign-page-section1__logo_clickpost' />
                                    </div>
                                    <div className='campaign-page-section1__logo-wrapper'>
                                        <div className='campaign-page-section1__logo campaign-page-section1__logo_fera' />
                                    </div>
                                    <div className='campaign-page-section1__logo-wrapper'>
                                        <div className='campaign-page-section1__logo campaign-page-section1__logo_growave' />
                                    </div>
                                    <div className='campaign-page-section1__logo-wrapper'>
                                        <div className='campaign-page-section1__logo campaign-page-section1__logo_klickly' />
                                    </div>
                                    <div className='campaign-page-section1__logo-wrapper'>
                                        <div className='campaign-page-section1__logo campaign-page-section1__logo_recart' />
                                    </div>
                                    <div className='campaign-page-section1__logo-wrapper'>
                                        <div className='campaign-page-section1__logo campaign-page-section1__logo_refferalcandy' />
                                    </div>
                                    <div className='campaign-page-section1__logo-wrapper'>
                                        <div className='campaign-page-section1__logo campaign-page-section1__logo_searchanise' />
                                    </div>
                                    <div className='campaign-page-section1__logo-wrapper'>
                                        <div className='campaign-page-section1__logo campaign-page-section1__logo_smsbump' />
                                    </div>
                                    <div className='campaign-page-section1__logo-wrapper'>
                                        <div className='campaign-page-section1__logo campaign-page-section1__logo_sufio' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='campaign-page-section2'>
                            <div className='campaign-page-section2__text-field-wrapper'>
                                <div className='campaign-page-section2__text-field-content'>
                                    <div className='campaign-page-section2__text'>
                                        We've partnered several of the top solution providers in eCommerce to make sure
                                        you capitalize on this monumental Black Friday/Cyber Monday.
                                    </div>
                                    <div className='campaign-page-section2__text'>
                                        For a limited time, our partners have put their heads together to deliver you
                                        all the best SaaS deals in one place.
                                    </div>
                                    <div className='campaign-page-section2__text'>
                                        Together we can crush all aspects of your holiday marketing strategy.{' '}
                                        <span className='sb'>#strongertogether</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='campaign-page-section3'>
                            <div className='campaign-page-section3__partners-wrapper'>
                                <div className='campaign-page-section3__partners-title'>
                                    Lock in your exclusive deals from these partners <br /> before Black Friday:
                                </div>
                                {Q4_PARTNERS.map((partner, key) => <Partner elemRef={myRefs[key]} key={key} partner={partner} />)}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    ref={formWrapperRef}
                    onClick={() => setIsHiddenForm(true)}
                    className={classnames('campaign-page__form-wrapper', {
                        'campaign-page__form-wrapper_hidden': isHiddenForm
                    })}
                >
                    <div ref={formRef}>
                        <div onClick={() => setIsHiddenForm(true)} className='campaign-page__form-close'>
                            X
                        </div>
                        <iframe
                            title='Q4 Form'
                            id='q4-campaign-form'
                            width={iframeSize.width}
                            height={iframeSize.height}
                            src='https://share.hsforms.com/1lV62_k7iRVucIr2vCIsn3Q48z09'
                        />
                    </div>
                </div>
                <div onClick={() => scroollToTop()} className='campaign-page__back-to-top-button'>
                    <div className='campaign-page__back-to-top-button-image' />
                </div>
                <Footer showForm={() => setIsHiddenForm(false)} myRefs={myRefs} />
            </div>
        );
    })
);

export default Q4CampaignPage;
