import clickpost from '../images/q4campaign/partners/clickpost.png';
import fera from '../images/q4campaign/partners/fera.png';
import growave from '../images/q4campaign/partners/growave.png';
import klickly from '../images/q4campaign/partners/klickly.png';
import recart from '../images/q4campaign/partners/recart.png';
import referralcandy from '../images/q4campaign/partners/referralcandy.png';
import searchanise from '../images/q4campaign/partners/searchanise.png';
import smsbump from '../images/q4campaign/partners/smsbump.png';
import sufio from '../images/q4campaign/partners/sufio.png';
export const Q4_PARTNERS = [
    {
        imgName: clickpost,
        category: 'Logistics Intelligence',
        name: 'Clickpost',
        about: 'Logistics management. Tracking pages. Automated order status',
        offer: '$1000 free credits for clickpost for new users.',
        link: 'http://clickpost.in/',
        id: 'logictic',
        menuName: 'Logistics Intelligence'
    },
    {
        imgName: fera,
        category: 'Social Proof & Reviews',
        name: 'Fera.ai',
        about: 'Fera Is A Social Proof & Review App That Increases Conversions and Trust',
        offer: 'Three month free trial of Fera',
        link: 'https://www.fera.ai?r=reffered_by-klicklyq4&discount=1',
        id: 'socials',
        menuName: 'Social Proof & Reviews'
    },
    {
        imgName: growave,
        category: 'Loyalty & rewards',
        name: 'Growave',
        about: 'The all-in-one marketing platform for small and medium-sized Shopify stores',
        offer: '60-day free trial',
        link: 'https://growave.io/?discount_code=60days4bfcm',
        id: 'loyality',
        menuName: 'Loyalty & rewards'
    },
    {
        imgName: klickly,
        category: 'Performance Marketing',
        name: 'Klickly',
        about: 'Commission-based advertising platform. Select bounty, pay on performance',
        offer: 'Get 3 weeks free ad campaign boosts',
        link: 'https://klickly.typeform.com/to/RIAxC7MS',
        id: 'performance',
        menuName: 'Performance Marketing'
    },
    {
        imgName: recart,
        category: 'Facebook Messenger',
        name: 'Recart',
        about: 'Reach your customers on their mobiles with 90%+ open rates',
        offer: 'Free account + setup until $10k is made',
        link: 'https://recart.com/q4-stronger-together-campaign',
        id: 'messenger',
        menuName: 'Facebook Messenger'
    },
    {
        imgName: referralcandy,
        category: 'Referral Marketing',
        name: 'ReferralCandy',
        about: 'ReferralCandy allows brands to setup and run customer referral programs.',
        offer: 'Get $50 off after your 30-day free trial',
        link: 'http://try.referralcandy.com/bfcm2020/',
        id: 'referral',
        menuName: 'Referral Marketing'
    },
    {
        imgName: searchanise,
        category: 'Site Search',
        name: 'Searchanise',
        about: 'Searchanise is an advanced site search, navigation and promo solution.',
        offer: '40% off first 12 months after free trial',
        link: 'https://www.searchanise.com/get/klickly40',
        id: 'search',
        menuName: 'Site Search'
    },
    {
        imgName: smsbump,
        category: 'SMS',
        name: 'SMSBump',
        about: 'SMSBump SMS Marketing & Automation',
        offer: '2 free months on any SMSBump paid plan',
        link: 'https://smsbump.com/shopify/install?r=VGF1S3BobTF1MVJXWlQ3YzFKelBaQT09',
        id: 'sms',
        menuName: 'SMS'
    },
    {
        imgName: sufio,
        category: 'Professional Invoicing',
        name: 'Sufio',
        about: 'Invoicing app for small businesses and online stores compliant worldwide.',
        offer: '20% off on your Sufio subscription',
        link: 'https://sufio.com/shopify/invoice/?coupon=strongertogether20',
        id: 'invoicing',
        menuName: 'Professional Invoicing'
    }
];
