import { useEffect } from 'react';
import { debounce } from 'lodash';
import { inject, observer } from 'mobx-react';

const App = inject('store')(
    observer(({ store, children }) => {
        const { checkIsMobileView } = store;

        const handleResize = debounce(() => {
            checkIsMobileView();
        }, 500);

        useEffect(() => {
            checkIsMobileView();
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return children;
    })
);

export default App;
