import React from 'react';
import superChargedEcomerce from '../../../../images/main/superChargedEcomerce.png';
import GradientBackground from '../../../../shared/components/GradientBackground';
import Button from '../../../ui/Button';
import './index.scss';

const UnifiedPlatformSection = () => {
    const handleClick = () => {
        window.open('/platform?scrollTo=unifiedPlatform', '_self');
    };

    return (
        <div className='klickly-unified-platform-wrapper'>
            <GradientBackground gradientType='pink-invert' />
            <div className='klickly-unified-platform'>
                <div className='klickly-unified-platform__content'>
                    <div className='klickly-unified-platform__left'>
                        <img
                            src={superChargedEcomerce}
                            className='klickly-unified-platform__image'
                            alt='Data-driven distributed commerce'
                        />
                    </div>
                    <div className='klickly-unified-platform__right'>
                        <div className='klickly-unified-platform__head'>Supercharged commerce</div>
                        <div className='klickly-unified-platform__title'>
                            The world’s most <br /> powerful & easy-to-
                            <br />
                            use distributed <br /> commerce platform
                        </div>
                        <div className='klickly-unified-platform__subtitle'>
                            Klickly’s proprietary data and AI commerce technology powers multi-channel offer <br />{' '}
                            prioritization, intelligent product discovery, in-ad purchases, and customer acquisition for{' '}
                            <br /> thousands of best-in-class ecommerce brands.
                        </div>
                        <Button onClick={handleClick} text='Learn More' />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default UnifiedPlatformSection;
