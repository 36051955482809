import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

const Header = () => {
    return (
        <header className='header'>
            <div className='header__content'>
                <div className='header__top'>
                    <Link to='/' className='k-logo' />
                    <nav className='header__nav-links'>
                        <Link className='header__nav-links__link' to='/press' rel='noopener noreferrer'>
                            Press
                        </Link>
                        <Link className='header__nav-links__link' to='/team' rel='noopener noreferrer'>
                            Team
                        </Link>
                        <a
                            className='header__nav-links__link'
                            href='https://blog.klickly.com'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Blog
                        </a>
                    </nav>
                </div>
            </div>
        </header>
    );
};
export default Header;
