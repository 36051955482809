import React from 'react';
import { inject, observer } from 'mobx-react';
import dataDrivenImage from '../../../../images/main/data-driven-image.png';
import GradientBackground from '../../../../shared/components/GradientBackground';
import Button from '../../../ui/Button';
import './index.scss';

const ContactSalesSection = inject('store')(
    observer(({ store, requestDemoModal }) => {
        const imageSRC = !store.isMobileView ? dataDrivenImage : dataDrivenImage;

        return (
            <div className='klickly-contact-sales-wrapper'>
                <GradientBackground />
                <div className='klickly-contact-sales'>
                    <div className='klickly-contact-sales__content'>
                        <div className='klickly-contact-sales__left'>
                            <div className='klickly-contact-sales__title'>
                                Data-driven <br /> distributed <br /> commerce
                            </div>
                            <div className='klickly-contact-sales__subtitle'>
                                The world’s only full-funnel consumer data <br /> software platform, built to power
                                customer <br /> acquisition for leading consumer brands
                            </div>
                            <Button onClick={requestDemoModal} text='Contact sales' />
                        </div>
                        <div className='klickly-contact-sales__right'>
                            <img
                                src={imageSRC}
                                className='klickly-contact-sales__image'
                                alt='Data-driven distributed commerce'
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);
export default ContactSalesSection;
