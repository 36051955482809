import React from 'react';
import superChargedEcomerce from '../../../../images/main/superChargedEcomerce.png';
import GradientBackground from '../../../../shared/components/GradientBackground';
import './index.scss';

const UnifiedPlatformSection = ({ targetRef }) => {
    return (
        <div className='klickly-unified-platform-2-wrapper' ref={targetRef}>
            <GradientBackground gradientType='pink-invert' />
            <div className='klickly-unified-platform-2'>
                <div className='klickly-unified-platform-2__content'>
                    <div className='klickly-unified-platform-2__left'>
                        <img
                            src={superChargedEcomerce}
                            className='klickly-unified-platform-2__image'
                            alt='Data-driven distributed commerce'
                        />
                    </div>
                    <div className='klickly-unified-platform-2__right'>
                        <div className='klickly-unified-platform-2__head'>Supercharged commerce</div>
                        <div className='klickly-unified-platform-2__title'>
                            The world’s most <br /> powerful & easy-to-
                            <br />
                            use distributed <br /> commerce platform
                        </div>
                        <div className='klickly-unified-platform-2__subtitle'>
                            Klickly’s proprietary data and AI commerce technology powers multi-channel offer <br />
                            prioritization, intelligent product discovery, in-ad purchases, and customer acquisition for{' '}
                            <br />
                            thousands of best-in-class brands.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default UnifiedPlatformSection;
